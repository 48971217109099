#NoMatch {
  height: 100vh;
  width: 100vw;
  background-color: #fff8f4;
  border: 0;
  padding: 0;

  #particles-js {
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    .particles {
      height: 100vh;
      width: 100vw;
    }
  }

  #content {
    position: absolute;
    background-image: url(./../../assets/img/background.png);
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    .container {
      background: inherit;
      height: 100vh;
      width: 100vw;
      margin: 0;
      padding: 0;
      .form {
      }
    }
  }
}
