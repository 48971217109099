#Help {
  height: 100vh;
  //background-attachment: fixed;
  background-position: right;
  background-color: transparent;
  background-image: url(./../../assets/img/helpBackground.svg);
  //background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;

  .container {
    text-align: center;
    height: 100%;
    overflow: hidden;

    .main-content {
      padding: 30px;
      border-radius: 15px;
      background-color: rgba(250, 250, 250, 0.9);
      @supports ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
        background-color: rgba(250, 250, 250, 0.5);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
      }
    }

    .version-content {
      background-color: rgba(255, 255, 255, 0.15);
      backdrop-filter: blur(15px);
      padding: 15px;
      border-radius: 5px;
    }
  }
}
