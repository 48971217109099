#ConfirmDrink {
  height: 100vh;
  width: 100vw;
  background-color: #fff8f4;
  border: 0;
  padding: 0;
  overflow: hidden;

  #particles-js {
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    overflow: hidden;
    .particles {
      height: 100vh;
      width: 100vw;
    }
  }

  #content {
    position: absolute;
    background-image: url(./../../assets/img/background.png);
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    .container {
      background: inherit;
      height: 100vh;
      width: 100vw;
      margin: 0;
      padding: 0;
      .form {
        margin: 10px;
        padding: 25px;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(15px);
        text-align: center;
      }
    }
  }
}
