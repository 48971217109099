#RoomStage {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  #MovingRef {
    border-radius: 20px;
    position: absolute;
    background-color: black;
    background-image: url(./../../assets/img/backgroundDefault.svg);
    background-position: center;
    background-repeat: no-repeat;
    //background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  #AvatarLayer {
    .avatar {
      //display: inline-block;
      position: absolute;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;

      border-radius: 50%;
      text-align: center;
      z-index: 500;
      .avatar-name {
        display: block;
        color: #fefefe;
        position: relative;
        top: -25px;
      }
    }

    .my-avatar {
      display: inline-block;

      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;

      border-radius: 50%;
      text-align: center;
      z-index: 600;
      position: absolute;
      cursor: pointer;
      .avatar-name {
        display: block;
        color: #fefefe;
        position: relative;
        top: -25px;
      }
    }

    .chat {
      margin: auto;
      text-align: center;
      z-index: 700;
      position: relative;
      .chat-img1 {
        position: absolute;
        height: 100%;
        width: 100%;
        background-image: url("./../../assets/img/room-full.svg");
        background-position: center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        animation: rotation 7s infinite linear;
      }
    }
  }
}
