#RoomContainer {
  height: 100vh;
  width: 100vw;
  background:url(./../../assets/img/helpBackground.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
  margin: 0;
  padding: 0;

  .room {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .video-group {
    z-index: 500;
    overflow: hidden;
    height: 100vh;
    background-color: #fff8f4;
  }

  .cursor {
    z-index: 1000;
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes rotation-inverse {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}
